import React, { useEffect } from 'react'
import useWidth from 'hooks/useWidth'
import { useNavigate } from "react-router-dom";
import cn from "classnames"
import styles from "./Menu.module.scss"
import Icon from 'customElements/icon/icon'
import rectangle from "../../assets/media/Rectangle_177.svg"
import tabs from "../../assets/media/Tabs-pana.svg"
import UseFulBlocks from 'customElements/UseFulBlocks/UseFulBlocks'
import backArrow from '../../assets/media/arrow-circle-left.svg'

import {useFulBlocks} from "../../utils/data/BlogMenu"
 
type ArticlesBlockType = {
  id: number,
  img: string,
  title: string,
  text: string,
}

function BlogMenu() {
  const [_, isMobile] = useWidth();
  const navigate = useNavigate();
  const ArticleBlockData: ArticlesBlockType[] | undefined = useFulBlocks;
  
  useEffect(() => {
      window.scrollTo({ top: 0 });
  }, []); 

  return (
    <>
    <div className={`${styles.main_back} contentPositioner d-flex gap-3`}>
      <div className="d-flex flex-column gap-md-3 gap-0">
        <Icon img={backArrow} width={isMobile ? '28px' : "45px"} height="45px" action={() => navigate(-1)}/>
      </div>
    </div>
    <section className={` ${cn(styles.main, 'contentPositioner contentPositionerVertical')} `}>
      <div className="contentPositionerTop pt-5 mt-5 row d-flex flex-lg-row flex-column gap-5 gap-lg-0 justify-content-between">
        <div className={`${styles.main}`}>
          {!isMobile && 
            <div className={`mb-5 ${styles.main_picture} position-relative`}>
              <Icon img={rectangle} width="100%" height="auto"/>
              <Icon img={tabs} height="unset"/>
            </div>
          }
          <div className={`${styles.main_usefuls} d-flex flex-column align-items-center`}>
            <div className="mb-5 mt-0 mt-md-3 pt-0 pt-md-3 row gx-4 gy-4">
                {ArticleBlockData.map(({id, img, title, text}) => 
                  <div className="col-md-4">
                      <UseFulBlocks blogMenu id={id} img={img} title={title} text={text}/>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default BlogMenu