/** Routers of Projects */
export const AppRoutes = {
  Home: "/",
  AboutUs: "/about-us",
  Blog: "/blog",
  Contact: "/contact",
  Projects: "/projects",
  Services: "/services",
  Testimonials: "/testimonials",
  BlogMenu: "/blogmenu",
  Portfolio: "/portfolio",
  FAQs: "/faqs",
  Privacy: "/privacy",
  All: "/*",
  Articles:"/articles",
  Portfolios: "/portfolios"
}
