import React, { useEffect } from 'react'
import useWidth from 'hooks/useWidth'
import { useNavigate } from "react-router-dom";
import cn from "classnames"
import styles from "./Portfolio.module.scss"
import Icon from 'customElements/icon/icon'
import PortfolioBlock from 'customElements/portfolioBlock/index'
import backArrow from '../../assets/media/arrow-circle-left.svg'

import { portfolioBlocks } from "../../utils/data/PortfolioBlocks"

type portfolioItems = {
    id: number,
    img: string,
    title: string,
    text: string[],
}

type PortfoliosBlockType = {
  itemsInfo: {icon: string, appTitles: string[]}
  items: portfolioItems[],
}

function Portfolio() {
  const [_, isMobile] = useWidth();
  const navigate = useNavigate();
  const PortfolioBlockData: PortfoliosBlockType | undefined = portfolioBlocks;
  
  useEffect(() => {
      window.scrollTo({ top: 0 });
  }, []); 

  return (
    <section className={` ${cn(styles.main, 'contentPositioner contentPositionerVertical')} `}>
        <div className={`${styles.main_back} d-flex gap-3`}>
            <div className="d-flex flex-column gap-md-3 gap-0">
                <Icon img={backArrow} width={isMobile ? '28px' : "45px"} height="45px" action={() => navigate(-1)}/>
            </div>
        </div>
        <div className="contentPositionerTop pt-5 mt-5 row d-flex flex-lg-row flex-column gap-5 gap-lg-0 justify-content-between">
            <div className={`${styles.main} mt-5 mt-md-0`}>
                <div className={`${styles.main_usefuls} d-flex flex-column align-items-center`}>
                    <div className="mb-5 mt-0 mt-md-3 pt-0 pt-md-3 row gx-4 gy-4">
                        {PortfolioBlockData.items.map(({id, title, text, img}) => 
                        <div className="col-md-4">
                            <PortfolioBlock id={id} img={img} title={title} text={text}/>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Portfolio