import React, {useState } from "react"
import { useNavigate } from "react-router-dom"
import cn from "classnames"
import styles from "./Faqs.module.scss"
import useWidth from "hooks/useWidth"
import { useFulBlocks } from '../../utils/data/Articles/ArticleBlocks/index'
import faqs from "../../assets/media/frequentlyImg.svg"

type ArticlesBlockType = {
    id: number,
    img: string,
    title: string,
    text: string,
}

export const Faqs: React.FC = () => {
    const navigate = useNavigate()
    const [_, isMobile] = useWidth();

    const [expandedItem, setExpandedItem] = useState<number | null>(null);

    const toggleExpanded = (index: number) => {
        setExpandedItem(prevExpandedItem =>
          prevExpandedItem === index ? null : index
        );
      };
    
    const questions = [
        {
            question: "What is the difference between native and cross-platform mobile app development?", 
            answer: "", 
            multyAnswer: [
                            "Native app development, using languages like Swift for iOS and Java/Kotlin for Android, ensures smooth performance and integration. With platform-specific APIs, developers create apps with quick response times and polished designs. Though challenges like maintaining separate codebases exist, the benefits of seamless integration make native development ideal for apps prioritizing performance and user satisfaction.",
                            "Cross-Platform App Development aims for compatibility on multiple platforms using a single codebase, exemplified by frameworks like React Native and Flutter. The advantages include broad reach, cost-effectiveness, and faster development cycles. While maintaining design consistency, it requires attention to performance optimization and platform-specific features."
                        ],
            finalTetxt: ""
        },
        {
            question: "What are the typical costs associated with mobile app development by Armara?",
            answer: "Mobile app development costs can vary widely depending on factors such as complexity, features, platform (iOS, Android, or both), design requirements, and the development team's location and expertise. Typically, costs can range from a five thousand dollars for a simple app to a hundred thousand dollars or more for a complex app with advanced features. It's essential to discuss your specific project requirements with a development company to get an accurate cost estimate.",
            multyAnswer: [],
            finalTetxt: ""
        },
        {
            question: "What factors should I consider when deciding between iOS and Android for my mobile app MVP?",
            answer: "When deciding between iOS and Android for your mobile app MVP (Minimum Viable Product), consider factors such as your target audience's demographics and preferences, market share of each platform in your target market, development costs and timeframes, ease of monetization (e.g., through app stores), and the specific features or capabilities required for your MVP. ",
            multuAnswer: [],
            finalTetxt: ""
        },
        {
            question: "What is Armara's deployment process for mobile apps?",
            answer: "The deployment process for mobile apps typically involves several steps, including app store submission, testing, and release. Armara or any development company would generally follow industry best practices, which may include:",
            multyAnswer: [
                            "Finalizing development and conducting thorough testing to ensure the app functions correctly.",
                            "Preparing app assets and metadata for submission to the respective app stores (Apple App Store for iOS, Google Play Store for Android).",
                            "Creating developer accounts and following platform-specific guidelines for app submission.",
                            "Submitting the app for review and approval by the app store.",
                            "Addressing any feedback or issues raised during the review process.",
                         ],
            finalTetxt: "Once approved, the app becomes available for download by users."
        },
        {
            question: "How does Armara handle ongoing support for mobile applications post-launch?",
            answer: "After launching a mobile app, ongoing support is crucial to ensure its continued success. Armara or any reputable development company may offer various support services, including:",
            multyAnswer: [
                             "Monitoring app performance and user feedback to identify and address issues promptly.",
                             "Providing updates and maintenance to address bugs, security vulnerabilities, and compatibility issues with new OS versions or devices.",
                             "Implementing new features or enhancements based on user feedback and evolving business requirements.",
                             "Regularly reviewing app analytics and performance metrics to inform future development efforts and optimizations.",
                        ],
            finalTetxt: ""
        }
        
    ]

    const ArticleBlockData: ArticlesBlockType[] | undefined = useFulBlocks;

    return (
        <section className={cn(styles.component, "contentPositionerVertical")} data-scroll-section="/faqs" id="/faqs">
            <div className={`contentPositioner`}>
                    <div className={`${styles.main} row gy-5 d-flex justify-content-between`}>
                        <div className={`${styles.main_questions} col-md-6`}>
                            <span className={styles.title}>Frequently Asked Questions</span>
                            <div className="accordion" id={styles.questions}>
                            {questions.map((item, index) => (
                                <div className="accordion-item" id={styles.accordion_item} key={index} onClick={() => toggleExpanded(index)}>
                                    <div className="accordion-header d-flex justify-content-between" id={`heading${index}`}>
                                        <span className={`${styles.question}`}>{item.question}</span>
                                        <span
                                            className={`accordion-button ${cn(expandedItem === index ? styles.minus_icon : styles.plus_icon, styles.accordion_icon)}`}
                                            aria-expanded={expandedItem === index}
                                            aria-controls={`collapse${index}`}
                                        >
                                        </span>
                                    </div>
                                    <div
                                        id={`collapse${index}`}
                                        className={`accordion-collapse collapse ${expandedItem === index ? 'show' : ''}`}
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#accordionExample"
                                        >
                                        <div className="accordion-body" style={{padding: '1rem 0'}}>
                                        <span>{item.answer}</span>
                                        {item.multyAnswer?.length &&
                                            <ul className={styles.multy_answer}>
                                                {item.multyAnswer.map((text,i) => <li  
                                                key={i}
                                                >{text}</li>)}
                                            </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                        <div className={`${styles.main_media} col-md-5`}>
                            <img src={faqs} />
                        </div>
                        {/* <div className={`${styles.main_usefuls} d-flex flex-column align-items-center`}>
                            <p>Useful Articles</p>
                            <div className="mb-5 mt-0 mt-md-3 pt-0 pt-md-3 row gx-4 gy-4">
                                {ArticleBlockData.map(({id, img, title, text}) => 
                                    <div className="col-md-4">
                                        <UseFulBlocks id={id} img={img} title={title} text={text}/>
                                    </div>
                                )}
                            </div>
                            <Button sm secondary width={isMobile ? "100%" : '143px'} action={() => navigate('/blogmenu')}>View More</Button>
                        </div> */}
                    </div>
            </div>
        </section>
    )
}
