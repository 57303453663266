import line from '../../assets/media/line.svg'
import calling from '../../assets/media/callingApp.svg'
import video from '../../assets/media/videoApp.svg'
import diet from '../../assets/media/dietApp.svg'
import sneaker from '../../assets/media/sneakerApp.svg'
import delivery from "../../assets/media/deliveryApp.svg"
import fitness from "../../assets/media/fitnessApp.svg"
import furniture from "../../assets/media/furnitureApp.svg"
import construction from "../../assets/media/constructionApp.svg"
import grocery from "../../assets/media/groceryApp.svg"
import travel from '../../assets/media/travelApp.svg'
import investment from "../../assets/media/investmentApp.svg"
import telemedicine from "../../assets/media/telemedicineApp.svg"
import booking from "../../assets/media/bookingApp.svg"
import forex from "../../assets/media/forexApp.svg"
import education from "../../assets/media/educationApp.svg"

export const portfolioBlocks = {
    itemsInfo: {icon: line, appTitles: ["Challenge", "Solution", "Result"]},
    items: [
    {   id:1,
        img: calling,
        title: "International Calling App",
        text: [
            'The challenge was to develop an app that would enable users to make cheap international calls without being tied down by contracts or hidden charges. Armara aimed to create a mobile solution that would accommodate both smartphone and landline users while prioritizing transparency and ease of use.',
            'The app offers users to make cheap international calls without being tied down by contracts or hidden charges. Whether users prefer to make calls from their smartphones or landline phones, the app accommodates both preferences seamlessly. The app provides real-time information on calling credit and call details, ensuring transparency and peace of mind for users.',
            'The app offers users to make cheap international calls without being tied down by contracts or hidden charges. Whether users prefer to make calls from their smartphones or landline phones, the app accommodates both preferences seamlessly. The app provides real-time information on calling credit and call details, ensuring transparency and peace of mind for users.',
        ]
    },
    {   id:2,
        img: video,
        title: "Video App",
        text: [
            'The rise of digital media and the proliferation of smartphones have democratized content creation, allowing individuals from various backgrounds to share their stories, expertise, and messages with the world. However, despite the accessibility of smartphones, many people still face challenges when it comes to producing high-quality videos.',
            'The app was designed with simplicity and versatility in mind, offering an intuitive interface and a comprehensive suite of features including trimming, cutting, and merging clips, as well as adding filters, transitions, and music tracks to enhance the final product. From recording vlogs and speeches to creating video resumes and self-tape auditions, the app provides users with the tools they need to bring their ideas to life.',
            'Since its launch, the app has garnered widespread acclaim and adoption, with over 1 million users worldwide. Content creators, professionals, educators, religious leaders, and job seekers alike have embraced the app as a valuable tool for sharing their messages and advancing their careers.',
        ]
    },
    {   id: 3,
        img: diet,
        title: "Diet App",
        text: [
            'Armara mobile app developers embarked on a mission to develop a comprehensive diet app aimed at revolutionizing the way individuals track their caloric intake.  Our developers faced challenges in ensuring data accuracy, developing complex algorithms for caloric intake based on a fatsecret database.',
            'The app was designed with simplicity and versatility in mind, offering an intuitive interface and a comprehensive suite of features including trimming, cutting, and merging clips, as well as adding filters, transitions, and music tracks to enhance the final product. From recording vlogs and speeches to creating video resumes and self-tape auditions, the app provides users with the tools they need to bring their ideas to life.',
            'Since its launch, the app has garnered widespread acclaim and adoption, with over 1 million users worldwide. Content creators, professionals, educators, religious leaders, and job seekers alike have embraced the app as a valuable tool for sharing their messages and advancing their careers.',
        ]
    },
    {   id: 4,
        img: sneaker,
        title: "Sneaker App",
        text: [
            'Armara team was hired to design and develop a mobile app for sneaker fans in the UK, gathering diverse sneaker brands, creating personalized recommendations, adding easy social sharing, and building a strong community.',
            "Armara's mobile development team meticulously crafted the app's design and developed both the frontend and backend for Android and iOS platforms. We curated various sneaker brands, utilized smart tech for custom suggestions, added seamless social sharing, and organized events to foster community spirit.",
            'Users appreciated the intuitive app with comprehensive info. Social features fostered community bonds, enhancing user loyalty and revenue.',
        ]
    },
    {   id: 5,
        img: delivery,
        title: "Food Delivery App",
        text: [
            'The client, a well known restaurant chain from Veena, hired Armara developers to create a food delivery app that enhances the dining experience by offering diverse culinary options, detailed menus, and personalized recommendations.',
            "Armara's iOS and Android developers crafted a user-friendly app with vibrant visuals, detailed descriptions, and a personalized recommendation system based on individual preferences. Seamless social media integration was implemented for sharing experiences and discovering new eateries.",
            'The app exceeded expectations, providing a diverse range of options and enhancing user satisfaction with personalized recommendations. Seamless social media integration fostered community engagement, turning food delivery into a communal experience. Overall, the app successfully transformed the dining experience, making every meal enjoyable and memorable.',
        ]
    },
    {   id: 6,
        img: fitness,
        title: "Fitness App",
        text: [
            'The primary objective was to provide users with a comprehensive app that encompasses workout routines, nutrition plans, wellness tips, and personalized recommendations tailored to individual goals and preferences.',
            "Armara developers created a fitness app which transforms the way individuals approach their sport activities. With detailed exercise descriptions, instructional videos, and progress tracking features, users can confidently navigate their fitness routines. Seamless integration with wearable fitness trackers and social media platforms enables users to monitor their progress, share achievements, and connect with a supportive community of like-minded individuals.",
            'The Armara mobile app development team has transformed the ways how individuals may approach their fitness routines by providing everything from workout plans to personalized nutrition recommendations and wellness tips. By seamlessly integrating with wearable fitness trackers and social media platforms, Armara fosters a supportive community while enhancing user engagement and accountability.',
        ]
    },
    {   id: 7,
        img: furniture,
        title: "Furniture Marketplace App",
        text: [
            'Creating a furniture marketplace app that redefines home furnishing shopping with diverse products, advanced search filters, personalized recommendations, and precise 3D visualization.',
            "Armara's Android and iOS developers collaborated to craft a cutting-edge app with a user-friendly interface, advanced filters, personalized recommendations, and 3D model integration. Secure payment gateways and transparent communication channels were prioritized for seamless transactions and trust-building.",
            "Armara's Furniture Marketplace App revolutionized home furnishing shopping, offering a diverse range of products and advanced features for browsing and purchasing. Precise 3D visualization enhanced the shopping experience, fostering a vibrant community of furniture enthusiasts. The app's excellence set a new industry standard, reflecting Armara's commitment to innovation.",
        ]
    },
    {   id: 8,
        img: construction,
        title: "Construction EquipRent App",
        text: [
            'The construction company faced challenges in efficiently managing equipment rentals, often leading to project delays and inefficiencies. Armara recognized the need for a robust solution that could address these challenges by offering a user-friendly platform for equipment rental management.',
            "Armara's team developed the Construction EquipRent App, integrating advanced features to meet the industry's demands. An intuitive Admin Panel was incorporated, providing a dashboard for managing users, equipment listings, payments, and generating insightful reports. Location Services were integrated with GPS technology, allowing users to locate equipment and rental sites effortlessly, while a map view facilitated visualizing equipment availability and rental locations.",
            "Upon its launch, the Construction EquipRent App revolutionized equipment rental in the construction industry. Contractors and construction firms embraced the app for its user-friendly interface, comprehensive features, and efficiency in managing equipment rentals.",
        ]
    },
    {   id: 9,
        img: grocery,
        title: "Grocery Store App",
        text: [
            'Our client, the grocery supply chain, faced challenges in adapting to the digital era, with traditional shopping methods often proving time-consuming and inefficient. Armara recognized the need for a user-friendly platform for purchasing groceries online',
            "The app introduced Smart Shopping Lists, allowing users to easily create and manage lists with features like auto-suggestions based on past purchases, voice input, and item categorization for better organization. The Barcode Scanner feature enabled users to scan product barcodes for quick addition to their shopping list and access detailed product information such as price, reviews, and nutritional facts.",
            "Upon its launch, the Armara Grocery Store App transformed the grocery shopping landscape, offering a convenient and efficient platform for purchasing groceries online. Consumers embraced the app for its intuitive design, vast selection of grocery items, and seamless shopping experience.",
        ]
    },
    {   id: 10,
        img: travel,
        title: "Travel App",
        text: [
            'Developing an advanced traveling app for a prominent travel agency in Italy posed several challenges. Our aim was to create a seamless platform that would revolutionize travel planning and management. However, integrating multiple data sources for accurate flight information, ensuring real-time updates, and optimizing the user interface for intuitive navigation proved to be significant hurdles.',
            "Our dedicated team worked tirelessly to overcome these challenges, devising a solution that would redefine the travel experience for users. The traveling app offers a user-friendly flight search feature, enabling travelers to effortlessly find and book flights to their desired destinations. Real-time flight status updates keep users informed of any changes or delays, ensuring a stress-free journey. Moreover, interactive maps with flight tracking functionality provide users with a visual representation of their flight's progress, enhancing their overall travel experience.",
            "The launch of our Traveling App has marked a significant milestone in the travel industry, providing users with a seamless and efficient way to plan and manage their journeys. Positive feedback from travelers highlights the app's success in simplifying travel planning and enhancing user satisfaction."
        ]
    },
    {   id: 11,
        img: investment,
        title: "Investment App",
        text: [
            'Armara partnered with a financial services firm to create WealthWise, a revolutionary investment management app. Facing challenges like data complexity and security concerns, Armara crafted a user-centric design, integrating advanced analytics and real-time market data.',
            "Armara integrated APIs from leading financial data providers to deliver real-time market data, news updates, and research reports directly within the app, enabling users to stay informed and make timely investment decisions. Robust security measures were implemented, ensuring user trust and regulatory compliance.",
            "The app's launch saw increased user engagement and financial literacy, with positive market reception. WealthWise sets a new standard in mobile investment management, showcasing Armara's expertise in delivering innovative solutions that empower users to make informed financial decisions and build wealth effortlessly."
        ]
    },
    {   id: 12,
        img: telemedicine,
        title: "Telemedicine App",
        text: [
            'Armara developed a telemedicine app for a prominent hospital chain in Greece,  addressing the pressing need for accessible healthcare services through remote consultations and medical tourism.',
            "The app offers a comprehensive suite of features including video consultations, appointment scheduling, prescription management, and secure payment processing. Through user-friendly interfaces and intuitive functionality, we aimed to provide both patients and healthcare professionals with a seamless telehealth experience.  In addition to these core functionalities, we integrated advanced features such as Remote Monitoring and Tele-Rehabilitation to enhance the overall telehealth experience.",
            "Telemedicine App exemplifies the transformative power of technology in greek healthcare, marking a significant milestone in the journey towards accessible, efficient, and patient-centric healthcare services."
        ]
    },
    {   id: 13,
        img: booking,
        title: "Property Booking App",
        text: [
            'Our client, a real estate agency from London, faced numerous hurdles in the traditional real estate landscape, including cumbersome paperwork, inefficient communication channels, and a lack of centralized platforms for property transactions.',
            "Armara tackled these challenges by designing a user-centric mobile app that consolidated buying, selling, and renting functionalities into one intuitive platform. We developed a robust backend system capable of securely managing transactions, listings, bookings, and payments, addressing the need for efficiency and security. ​​The app also offers host management tools, an admin panel for secure transactions, and location services for easy navigation.",
            "The client benefited from the app's centralized approach, experiencing increased efficiency and reduced administrative burdens. Positive feedback and growing user engagement validated Armara's innovative solution. With efficiency and convenience, it transforms the London real estate scene, enhancing user experience and satisfaction."
        ]
    },
    {   id: 14,
        img: forex,
        title: "Forex App",
        text: [
            'Redeveloping the forex app for a leading financial company from Cyprus posed distinct challenges. Our aim was to create a sophisticated yet user-friendly platform to enhance trading experiences. Enhancing account history and transaction details required meticulous attention to provide comprehensive financial insights.',
            "To overcome these challenges, Armara team embarked on an extensive redesign process focusing on optimizing functionality and user experience. The revamped finance forex app features an intuitive interface simplifying trading operations without sacrificing advanced functionalities. Users can seamlessly execute trades, set limit orders, and manage investment portfolios with ease and precision. Enhanced account history and transaction details offer transparent visibility into financial activities, empowering informed decision-making.",
            "The launch of the redesigned Forex App received positive feedback of the users, highlighting its intuitive interface and robust trading capabilities."
        ]
    },
    {   id: 15,
        img: education,
        title: "Education App",
        text: [
            'Developing an innovative education app for a prestigious university in Belgium posed several challenges. We aimed to integrate gamified experiences into the curriculum to enhance learning outcomes.',
            "Our team worked tirelessly to overcome these challenges, creating a solution that reshaped the educational landscape. The education app seamlessly integrates gamified learning experiences while maintaining academic rigor. Features like points, badges, levels, and rewards incentivize student participation, fostering continuous improvement and academic excellence. The app offers robust progress tracking and performance analytics functionalities.",
            "The Education App's launch has transformed digital learning at the university, inspiring academic excellence. Gamified elements and comprehensive progress tracking have increased student engagement and performance."
        ]
    },
]}