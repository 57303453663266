import pana from "../../../../assets/media/Tabs-pana.png";
import amigo from "../../../../assets/media/Tabs-amico.png";
import bro from "../../../../assets/media/Tabs-bro.png";

type BlockType = {
    id: number,
    img: string,
    title: string,
    text: string,
}

export const useFulBlocks: BlockType[] = [
    {   id: 1,
        img: pana,
        title: "Ge Refrigerator Not Cooling",
        text: 'The technician came and fixed my air conditioner on the roof, he changed the capacitor....'
    },
    {   id: 2,
        img: amigo,
        title: "GE Dishwasher is Not Draining",
        text: 'The technician came and fixed my air conditioner on the roof, he changed the capacitor....'
    },
    {   id: 3,
        img: bro,
        title: "GE Dishwasher is Not Draining",
        text: 'The technician came and fixed my air conditioner on the roof, he changed the capacitor....'
    },
];