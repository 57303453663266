import menuIcon from "../../assets/media/menu.png";
import useScrollRouting from "../../routes/scrollRoute";
import { useLocation } from "react-router-dom";
import useWidth from "hooks/useWidth";
import { useElement } from '../../utils/ElementContext';
import style from "./Header.module.scss";
import logoLink from "assets/media/logo.svg";
import cn from "classnames";
import LeftMenu from "components/LeftMenu/LeftMenu";
import NavigationLinks from "components/NavigationLinks/NavigationLinks";
import Button from "customElements/button/button";
import Icon from "customElements/icon/icon";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export const Header: React.FC = () => {
  const location = useLocation();
  const elementRef = useElement();

  const [_, isMobileforButtons] = useWidth(1200);
  const [__, isMobile] = useWidth(600);

  const [menu, setMenu] = useState(false);
  const activeLink = useScrollRouting();
  const imgRef = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setMenu(false)
  }, [location.pathname])

  const handleClick = () => {
    elementRef.current && elementRef.current.scrollIntoView(); 
  };

  const headerStyle = {
    backgroundColor:
      scrollPosition > 0 ? "rgba(255, 255, 255, 1)" : "transparent",
    transition: "background-color 0.3s ease",
  };

  return (
    <header className={`${cn(style.component, 'contentPositioner')}`} style={headerStyle}>
      <div className={cn(style.contentWrapper, style.header, isMobile ? style.mobileHeader : '')}>
        <Link
          className={style.logoLink}
          to={"/"}
          target="_self"
          rel="noopener noreferrer"
        >
          <Icon md img={logoLink} width={isMobileforButtons ? "150px" : '203px'} />
        </Link>
        <NavigationLinks activeLink={activeLink} style={style} />
        <img
          ref={imgRef}
          onClick={() => setMenu(!menu)}
          className={cn(style.menu, menu && style.opened_menu)}
          src={menuIcon}
          alt="Menu"
        />
        <div className={style.desktopButton}>
          <Button
            sm
            width={isMobileforButtons ? "125px" : '142px'}
            secondary
            action={handleClick}
          >
            Get in Touch
          </Button>
        </div>
      </div>

      <div className={`${style.mobileButton} py-4 `}>
        <Button 
          sm
          width={isMobile ? "100%" : "142px"}
          secondary
          action={handleClick}
        >
          Get in Touch
        </Button>
      </div>
      <LeftMenu active={menu} imgRef={imgRef} setMenu={setMenu}  />

    </header>
  );
};

export default Header;