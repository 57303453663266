import  tabs1 from  "../../assets/media/Tabs-pana.png"
import  tabs2 from  "../../assets/media/Tabs-amico.png"
import  tabs3 from  "../../assets/media/Tabs-bro.png"

export const useFulBlocks = [
    {   id:1,
        img: tabs1,
        title: "Ge Refrigerator Not Cooling",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:2,
        img: tabs2,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:3,
        img: tabs3,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:4,
        img: tabs1,
        title: "Ge Refrigerator Not Cooling",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:5,
        img: tabs2,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:6,
        img: tabs3,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:7,
        img: tabs1,
        title: "Ge Refrigerator Not Cooling",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:8,
        img: tabs2,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:9,
        img: tabs3,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:10,
        img: tabs1,
        title: "Ge Refrigerator Not Cooling",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:11,
        img: tabs2,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
    {   id:12,
        img: tabs3,
        title: "GE Dishwasher is Not Draining",
        text: 'There Technician came and fixed my air conditioner on the roof, he changed the capacitor there Technician came and fixed my air conditioner on the...'
    },
];