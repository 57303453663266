import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import eventEmitter from '../../utils/eventEmitter.js';
import { AboutUs } from 'pages/AboutUs/AboutUs'
import { Contact } from 'pages/Contact/Contact'
import { Faqs } from 'pages/FAQs/Faqs'
import { Home } from 'pages/Home/Home'
import { Projects } from 'pages/Projects/Projects'
import { Services } from 'pages/Services/index'
import { Testimonials } from 'pages/Testimonials/index'


function Outlet() {
  const location = useLocation();
  const prevUrl = location || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({top: 0});
  }, [navigate, pathname]); 
  
  useEffect(() => {
    document.getElementById(prevUrl.state?.elementRef)?.scrollIntoView();
    // const handleServicesComponentMounted = (domElement: HTMLDivElement) => {
    //   // Assign the DOM element contact to the ref
    //   prevUrl.state?.elementRef === '/services' && domElement?.scrollIntoView();
    // };
    //eventEmitter.on('HomeMounted', handleHomeComponentMounted)
    return () => { 
      // console.log("componentUnmount", handleServicesComponentMounted);
    };
  }, [prevUrl.state?.elementRef])
  
  return (
    <>
        <Home />
        <Projects />
        <AboutUs/>
        <Services />
        <Testimonials />
        <Faqs />
        {/* <Contact/> */}
    </>
  )
}

export default Outlet