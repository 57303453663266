import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from "classnames";
import styles from "./block.module.scss";
import Icon from 'customElements/icon/icon';

interface Props {
    id: number;
    img: string;
    title: string;
    text: string[];
}

const PortfolioBlock = ({ id, img, title, text }: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const truncateText = () => {
            const el = contentRef.current;
            if (!el) return;

            const truncate = (text: string) => {
                while (el.scrollHeight > el.clientHeight) {
                    el.textContent = text.replace(/\W*\s(\S)*$/, '...');
                    text = el.textContent || '';
                }
            };

            if (el && text.length > 0) {
                el.textContent = text[0];
                truncate(text[0]);
            }
        };

        truncateText();
        window.addEventListener('resize', truncateText);

        return () => window.removeEventListener('resize', truncateText);
    }, [text]);

    return (
        <Link to={`/portfolios?id=${id}`}>
            <div className={`${cn(styles.main)} p-4 d-flex flex-column align-items-center gap-3`}>
                <div className={`w-100 ${cn(styles.main_media)} d-flex justify-content-center`}>
                    <Icon width='100px' height='auto' img={img} />
                </div>
                <div className={`${styles.main_info} d-flex flex-column gap-2`}>
                    <span>{title}</span>
                    <span ref={contentRef}>{text[0]}</span>
                </div>
            </div>
        </Link>
    );
}

export default PortfolioBlock;