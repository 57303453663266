import React, { useEffect } from "react";
import cn from "classnames"
import { useNavigate, useLocation } from "react-router-dom";
import useWidth from "hooks/useWidth";

import styles from "./Portfolio.module.scss";

import { portfolioBlocks } from "../../utils/data/PortfolioBlocks";
import Icon from '../../customElements/icon/icon'
import backArrow from '../../assets/media/arrow-circle-left.svg'
import backgroundImg from "../../assets/media/smallBackground.svg"

type portfolioItems = {
    id: number,
    img: string,
    title: string,
    text: string[],
}

type portfolioMainInfo = {
    icon: string, 
    appTitles: string[]
}

const Portfolios = () => {
  const [_, isMobile] = useWidth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idPage = searchParams.get('id');
  
  const portfolioMainInfo: portfolioMainInfo = portfolioBlocks.itemsInfo || {icon: '', appTitles: []};
  const filteredBlocks: portfolioItems | undefined = portfolioBlocks.items.find((block) => block.id === Number(idPage)) || portfolioBlocks.items[0];

  useEffect(() => {
    window.scrollTo({top: 0});
  }, [idPage])

  return (
      <section className={`${cn(styles.main, 'contentPositioner contentPositionerVertical')}`} data-scroll-section="/" id="/">
        <div className={`contentPositionerTop ${styles.main_content} d-flex flex-column gap-3`}>
            <div className={`${styles.main_back} d-flex gap-3`}>
                <div className="d-flex flex-column gap-md-3 gap-0">
                    <Icon img={backArrow} width={isMobile ? '28px' : "45px"} height="45px" action={() => navigate(-1)}/>
                </div>
            </div>
            <div className="contentPositionerVertical">
                <p>{filteredBlocks.title}</p>
                <div className={`mb-5 ${styles.main_picture} position-relative`}>
                    <Icon img={backgroundImg} width="100%" height="100%"/>
                    <Icon img={filteredBlocks.img} width={'30%'} height="auto"/>
                </div>
                
                {filteredBlocks && (
                <ul className="my-3 d-flex flex-column gap-3">
                    {filteredBlocks.text.map((item, index) => (
                    <li key={index}>
                        <div className="d-flex gap-3">
                            <Icon img={portfolioMainInfo.icon} width="16px" height="8px" />
                            <div className={`${styles.description} d-flex flex-column gap-2`}>
                                <span>{portfolioMainInfo.appTitles[index]}</span>
                                <span>{item}</span>
                            </div>
                        </div>
                        {index !== filteredBlocks.text.length - 1 && <hr />}
                    </li>
                    ))}
                </ul>
                )}
            </div>
        </div>
      </section>
  );
}

export default Portfolios;
