// UseFulBlocks.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import cn from "classnames"
import styles from "./UseFulBlocks.module.scss"; 
import Icon from 'customElements/icon/icon';

interface Props {
    id: number; 
    img: string;
    title: string;
    text: string;
    blogMenu?: boolean,
}


function UseFulBlocks({ id, img, title, text, blogMenu}: Props) { 
    return (
        <Link to={`/articles?id=${id}`}>
          <div className={`${cn(styles.main, !blogMenu && styles.blog_menu)} p-4 d-flex flex-column align-items-center gap-3`}>
            <div className={`w-100 ${cn(styles.main_media, blogMenu && styles.blog_menu)} d-flex justify-content-center`}>
              <Icon width='auto' height='auto' img={img} />
            </div>
            <div className='d-flex flex-column gap-2'>
              <span>{title}</span>
              <span>{text}</span>
            </div>
          </div>
        </Link>
    );
}

export default UseFulBlocks;
